import { createGlobalStyle } from 'styled-components';

import charlotte from '../fonts/LLCHARLO.TTF';
import dolato from '../fonts/DolatodeStatoFont.ttf';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
  @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

  @font-face {
    font-family: 'LLCharlotte';
    src: url(${charlotte});
  }

  @font-face {
    font-family: 'Dolato';
    src: url(${dolato});
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
    scroll-behavior: smooth;
  }

  body {
    background: #ffffff;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased !important;
  }

  .default-cursor {
    cursor: default;
  }

  .icon-align {
    vertical-align: -6px;
  }

  .big-icon-align {
    vertical-align: -5px;
    font-size: 31px;
  }
  .answer {
    /* .material-icons {
      vertical-align: -6px;
      margin-right: 5px;
    } */
    .answer-success {
      color: green;
    }

    .answer-error {
      color: #ca1e15 !important;
    }

    .answer-icon {
      vertical-align: -6px !important;
      padding-right: 5px;
    }
  }
`;
