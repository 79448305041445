import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledButton = styled.div`
  text-align: center !important;
`;

export const StyledButtonNavLink = styled(NavLink)`
  background: #563e8e;
  text-transform: uppercase;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.88px;
  text-align: left;
  color: #51bf96;
  padding: 15px 42px;

  &:hover {
    background: #51bf96;
    color: white;
  }
`;

export const StyledButtonSubmit = styled.button`
  background: #563e8e !important;
  text-transform: uppercase !important;
  border-radius: 3px !important;
  font-family: Montserrat !important;
  font-size: 22px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  // line-height: 1.36 !important;
  letter-spacing: 0.88px !important;
  text-align: left !important;
  color: #51bf96 !important;
  padding: 15px 42px !important;
  height: auto !important;
  &:hover {
    background: #443072;
    color: #18a075;
  }
`;

export const StyledButtonOnClick1 = styled.button`
  background: #472e6d !important;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.12);

  border-radius: 5px !important;
  border: none;
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  // line-height: 1.36 !important;
  letter-spacing: 0.88px !important;
  text-align: left !important;
  color: #fcfaec !important;
  padding: 15px 42px !important;
  height: auto !important;
  /* &:hover {
    background: #443072;
    color: #18a075;
  } */

  /* 

  border-radius: 5px;

box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.12);

background-color: #472e6d; */
`;
