import styled from 'styled-components';

export const StyledTitle = styled.h1`
  /* font-family: 'LLCharlotte'; */
  font-family: 'Dolato';
  font-size: 80px;
  color: ${props => props.color};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  text-align: ${props => (props.center ? 'center' : 'none')};
  max-width: ${props => (props.limitWidth ? '500px' : '100%')};

  @media (max-width: 1023px) {
    font-size: 50px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
  }
`;
