/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
// import ScrollAnimation from 'react-animate-on-scroll';
import {
  Image,
  FlagDown,
  FlagDownShadow,
  FlagRight,
  FlagRightShadow,
  H1,
  H2,
  H3,
  H4,
  Table,
} from './styles';
import Button from '../../components/Button';
import LanguageContext from '../../components/LanguageContext/context';

import pote1 from '../../images/potes/POTE1_LADO.png';
import pote2 from '../../images/potes/POTE_1KG.png';
import pote3 from '../../images/potes/POTE_320G.png';
/* import pote3 from '../../images/potes/POTE3.png';
import pote4 from '../../images/potes/POTE4.png'; */

export default function Products() {
  const [bigBowlOpen, setbigBowlOpen] = useState(false);
  const [smallBowl1Open, setSmallBowl1Open] = useState(false);
  const [smallBowl2Open, setSmallBowl2Open] = useState(false);
  const [bowl1Open, setbowl1Open] = useState(false);
  const [bowl2Open, setbowl2Open] = useState(false);
  // const [bowl3Open, setbowl3Open] = useState(false);

  const openBigBowl = () => {
    setbigBowlOpen(true);
  };

  const closeBigBowl = () => {
    setbigBowlOpen(false);
  };

  const toggleBigBowl = () => {
    setbigBowlOpen(!bigBowlOpen);
  };
  const toggleBowl1 = () => {
    setbowl1Open(!bowl1Open);
  };
  const toggleBowl2 = () => {
    setbowl2Open(!bowl2Open);
  };
  // const toggleBowl3 = () => {
  //   setbowl3Open(!bowl3Open);
  // };

  const openSmallBowl1 = () => {
    setSmallBowl1Open(true);
  };

  const closeSmallBowl1 = () => {
    setSmallBowl1Open(false);
  };

  const openSmallBowl2 = () => {
    setSmallBowl2Open(true);
  };

  const closeSmallBowl2 = () => {
    setSmallBowl2Open(false);
  };

  const Context = useContext(LanguageContext);
  const tabela = Context.languages.tabelaDados;

  return (
    <div style={{ marginBottom: '15px' }}>
      <div className="column is-12 is-hidden-mobile">
        <div className="columns is-multiline has-text-centered">
          <>
            {!bigBowlOpen ? (
              <>
                <div className="column is-12 is-centered has-text-centered">
                  <Image src={pote1} alt="" />
                </div>
                <div
                  className="column is-12 is-centered has-text-centered default-cursor"
                  onClick={openBigBowl}
                  role="button"
                  tabIndex={0}
                >
                  <H1>
                    {Context.languages.nomesProdutos.original}{' '}
                    <i className="material-icons big-icon-align">
                      keyboard_arrow_right
                    </i>
                  </H1>
                  <H3>4,5 kg</H3>
                </div>
              </>
            ) : (
              <>
                <div
                  className="column is-7 is-centered has-text-centered"
                  style={{ zIndex: '2' }}
                >
                  <div className="columns is-multiline">
                    <div className="column is-12">
                      <Image src={pote1} alt="" />
                    </div>
                    <div className="column is-12 is-paddingless is-marginless">
                      <div
                        className="column is-12 is-centered has-text-centered default-cursor"
                        onClick={closeBigBowl}
                        role="button"
                        tabIndex={0}
                      >
                        <H1>
                          {Context.languages.nomesProdutos.original}
                          <i className="material-icons big-icon-align">
                            keyboard_arrow_left
                          </i>
                        </H1>
                        <H3>4,5 kg</H3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="column is-5 is-centered has-text-centered is-paddingless is-marginless"
                  style={{ zIndex: '1' }}
                >
                  <FlagRightShadow>
                    <FlagRight>
                      <Table>
                        <tbody>
                          <tr>
                            <td className="left">{tabela.campo1}</td>
                            <td className="right">514kj / 122kcal</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo2}</td>
                            <td className="right">3,6g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo3}</td>
                            <td className="right">0,9g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo4}</td>
                            <td className="right">19,4g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo5}</td>
                            <td className="right">14g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo6}</td>
                            <td className="right">4,4g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo7}</td>
                            <td className="right">0,9g</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo8}</td>
                            <td className="right">0,05mg</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo9}</td>
                            <td className="right">13mg</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo10}</td>
                            <td className="right">32,2mg</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo11}</td>
                            <td className="right">0,05mg</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo12}</td>
                            <td className="right">118mg</td>
                          </tr>
                          <tr>
                            <td className="left">{tabela.campo13}</td>
                            <td className="right">0,0mg</td>
                          </tr>
                        </tbody>
                      </Table>
                    </FlagRight>
                  </FlagRightShadow>
                </div>
                <div
                  className="column is-5 is-centered has-text-centered is-paddingless is-marginless"
                  style={{ zIndex: '1' }}
                />
              </>
            )}
          </>
        </div>
      </div>
      <div className="column is-4 has-text-centered is-hidden-tablet">
        {/* <img src={pote1} alt="" /> */}
        <Image src={pote1} alt="" />
      </div>

      <div
        className="column is-4 has-text-centered is-hidden-tablet"
        onClick={toggleBigBowl}
        role="button"
        tabIndex={0}
      >
        {bigBowlOpen && (
          <H2>
            {Context.languages.nomesProdutos.original}
            <i className="material-icons big-icon-align">keyboard_arrow_up</i>
          </H2>
        )}
        {!bigBowlOpen && (
          <H2>
            {Context.languages.nomesProdutos.original}
            <i className="material-icons big-icon-align">keyboard_arrow_down</i>
          </H2>
        )}
        <H4>4,5 kg</H4>
      </div>
      {bigBowlOpen && (
        <div className="column has-text-centered is-hidden-tablet">
          <FlagDownShadow>
            <FlagDown>
              <Table>
                <tbody>
                  <tr>
                    <td className="left">{tabela.campo1}</td>
                    <td className="right">514kj / 122kcal</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo2}</td>
                    <td className="right">3,6g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo3}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo4}</td>
                    <td className="right">19,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo5}</td>
                    <td className="right">14g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo6}</td>
                    <td className="right">4,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo7}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo8}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo9}</td>
                    <td className="right">13mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo10}</td>
                    <td className="right">32,2mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo11}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo12}</td>
                    <td className="right">118mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo13}</td>
                    <td className="right">0,0mg</td>
                  </tr>
                </tbody>
              </Table>
            </FlagDown>
          </FlagDownShadow>
        </div>
      )}

      <div className="column is-12"> </div>
      <div className="column is-12 is-hidden-mobile"> </div>
      <div className="column is-12 is-hidden-mobile"> </div>
      <div className="column is-4 has-text-centered is-hidden-tablet">
        <img src={pote2} alt="" style={{ width: '442px' }} />
      </div>

      <div
        className="column is-4 has-text-centered is-hidden-tablet"
        onClick={toggleBowl1}
        role="button"
        tabIndex={0}
      >
        {bowl1Open && (
          <H2>
            {Context.languages.nomesProdutos.premium}
            <i className="material-icons big-icon-align">keyboard_arrow_up</i>
          </H2>
        )}
        {!bowl1Open && (
          <H2>
            {Context.languages.nomesProdutos.premium}
            <i className="material-icons big-icon-align">keyboard_arrow_down</i>
          </H2>
        )}
        <H4>1 kg</H4>
      </div>
      {bowl1Open && (
        <div className="column has-text-centered is-hidden-tablet">
          <FlagDownShadow>
            <FlagDown>
              <Table>
                <tbody>
                  <tr>
                    <td className="left">{tabela.campo1}</td>
                    <td className="right">514kj / 122kcal</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo2}</td>
                    <td className="right">3,6g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo3}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo4}</td>
                    <td className="right">19,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo5}</td>
                    <td className="right">14g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo6}</td>
                    <td className="right">4,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo7}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo8}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo9}</td>
                    <td className="right">13mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo10}</td>
                    <td className="right">32,2mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo11}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo12}</td>
                    <td className="right">118mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo13}</td>
                    <td className="right">0,0mg</td>
                  </tr>
                </tbody>
              </Table>
            </FlagDown>
          </FlagDownShadow>
        </div>
      )}

      {/* POTE 3 */}
      <div className="column is-4 has-text-centered is-hidden-tablet">
        <img src={pote3} alt="" style={{ height: '180px' }} />
      </div>

      <div
        className="column is-4 has-text-centered is-hidden-tablet"
        onClick={toggleBowl2}
        role="button"
        tabIndex={0}
      >
        {bowl2Open && (
          <H2>
            {Context.languages.nomesProdutos.banana}
            <i className="material-icons big-icon-align">keyboard_arrow_up</i>
          </H2>
        )}
        {!bowl2Open && (
          <H2>
            {Context.languages.nomesProdutos.banana}
            <i className="material-icons big-icon-align">keyboard_arrow_down</i>
          </H2>
        )}
        <H4>320 g</H4>
      </div>
      {bowl2Open && (
        <div className="column has-text-centered is-hidden-tablet">
          <FlagDownShadow>
            <FlagDown>
              <Table>
                <tbody>
                  <tr>
                    <td className="left">{tabela.campo1}</td>
                    <td className="right">514kj / 122kcal</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo2}</td>
                    <td className="right">3,6g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo3}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo4}</td>
                    <td className="right">19,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo5}</td>
                    <td className="right">14g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo6}</td>
                    <td className="right">4,4g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo7}</td>
                    <td className="right">0,9g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo8}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo9}</td>
                    <td className="right">13mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo10}</td>
                    <td className="right">32,2mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo11}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo12}</td>
                    <td className="right">118mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo13}</td>
                    <td className="right">0,0mg</td>
                  </tr>
                </tbody>
              </Table>
            </FlagDown>
          </FlagDownShadow>
        </div>
      )}

      {/* <div className="column is-12 is-hidden-tablet"> </div>

      <div className="column is-4 has-text-centered is-hidden-mobile">
        <img src={pote3} alt="" />
      </div>
      <div className="column is-4 has-text-centered is-hidden-tablet">
        <img src={pote3} alt="" />
      </div>

      <div
        className="column is-4 has-text-centered is-hidden-tablet default-cursor"
        onClick={toggleBowl2}
        role="button"
        tabIndex={0}
      >
        {bowl2Open && (
          <H2>
            {Context.languages.nomesProdutos.banana}
            <i className="material-icons big-icon-align">keyboard_arrow_up</i>
          </H2>
        )}
        {!bowl2Open && (
          <H2>
            {Context.languages.nomesProdutos.banana}
            <i className="material-icons big-icon-align">keyboard_arrow_down</i>
          </H2>
        )}
        <H4>4,5 kg</H4>
      </div>
      {bowl2Open && (
        <div className="column has-text-centered is-hidden-tablet">
          <FlagDownShadow>
            <FlagDown>
              <Table>
                <tbody>
                  <tr>
                    <td className="left">{tabela.campo1}</td>
                    <td className="right">488kj</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo2}</td>
                    <td className="right">115kcal</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo3}</td>
                    <td className="right">1,2g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo4}</td>
                    <td className="right">5,6g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo5}</td>
                    <td className="right">0,0g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo6}</td>
                    <td className="right">22,5g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo7}</td>
                    <td className="right">18g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo8}</td>
                    <td className="right">32,2mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo9}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo10}</td>
                    <td className="right">0,0mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo11}</td>
                    <td className="right">118mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo12}</td>
                    <td className="right">13mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo13}</td>
                    <td className="right">6,3g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo14}</td>
                    <td className="right">0,06mg</td>
                  </tr>
                </tbody>
              </Table>
            </FlagDown>
          </FlagDownShadow>
        </div>
      )}

      <div className="column is-12 is-hidden-tablet"> </div>

      <div className="column is-4 has-text-centered is-hidden-mobile">
        <img src={pote4} alt="" />
      </div>
      <div className="column is-4 has-text-centered is-hidden-tablet">
        <img src={pote4} alt="" />
      </div>
      <div
        className="column is-4 has-text-centered is-hidden-tablet default-cursor"
        onClick={toggleBowl3}
        role="button"
        tabIndex={0}
      >
        {bowl3Open && (
          <H2>
            {Context.languages.nomesProdutos.frutas}
            <i className="material-icons big-icon-align">keyboard_arrow_up</i>
          </H2>
        )}
        {!bowl3Open && (
          <H2>
            {Context.languages.nomesProdutos.frutas}
            <i className="material-icons big-icon-align">keyboard_arrow_down</i>
          </H2>
        )}
        <H4>4,5 kg</H4>
      </div>
      {bowl3Open && (
        <div className="column has-text-centered is-hidden-tablet">
          <FlagDownShadow>
            <FlagDown>
              <Table>
                <tbody>
                  <tr>
                    <td className="left">{tabela.campo1}</td>
                    <td className="right">488kj</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo2}</td>
                    <td className="right">115kcal</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo3}</td>
                    <td className="right">1,2g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo4}</td>
                    <td className="right">5,6g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo5}</td>
                    <td className="right">0,0g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo6}</td>
                    <td className="right">22,5g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo7}</td>
                    <td className="right">18g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo8}</td>
                    <td className="right">32,2mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo9}</td>
                    <td className="right">0,05mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo10}</td>
                    <td className="right">0,0mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo11}</td>
                    <td className="right">118mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo12}</td>
                    <td className="right">13mg</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo13}</td>
                    <td className="right">6,3g</td>
                  </tr>
                  <tr>
                    <td className="left">{tabela.campo14}</td>
                    <td className="right">0,06mg</td>
                  </tr>
                </tbody>
              </Table>
            </FlagDown>
          </FlagDownShadow>
        </div>
      )} */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className="column is-12 has-text-centered is-hidden-mobile">
            <img src={pote2} alt="" style={{ width: '442px' }} />
          </div>
          <div className="column is-12 is-paddingless is-marginless has-text-centered is-hidden-mobile">
            <H2>{Context.languages.nomesProdutos.premium}</H2>
            <H4>1 kg</H4>
          </div>
          {/* <div className="column is-4 is-paddingless is-marginless has-text-centered is-hidden-mobile">
        <H2>{Context.languages.nomesProdutos.banana}</H2>
        <H4>4,5 kg</H4>
      </div>
      <div className="column is-4 is-paddingless is-marginless has-text-centered is-hidden-mobile">
        <H2>{Context.languages.nomesProdutos.frutas} </H2>
        <H4>4,5 kg</H4>
      </div> */}
          <div
            className={`column is-12 has-text-centered is-hidden-mobile ${
              !smallBowl1Open ? '' : 'is-hidden'
            }`}
          >
            <Button to="/" type="onclick" onClick={openSmallBowl1}>
              {/* Exibir tabelas nutricionais */}
              {Context.languages.tabela}
              <i className="material-icons icon-align">keyboard_arrow_down</i>
            </Button>
          </div>
          <div
            className={`column is-12 is-paddingless is-marginless has-text-centered is-hidden-mobile ${
              smallBowl1Open ? '' : 'is-hidden'
            }`}
          >
            <FlagDownShadow>
              <FlagDown>
                <Table>
                  <tbody>
                    <tr>
                      <td className="left">{tabela.campo1}</td>
                      <td className="right">514kj / 122kcal</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo2}</td>
                      <td className="right">3,6g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo3}</td>
                      <td className="right">0,9g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo4}</td>
                      <td className="right">19,4g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo5}</td>
                      <td className="right">14g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo6}</td>
                      <td className="right">4,4g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo7}</td>
                      <td className="right">0,9g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo8}</td>
                      <td className="right">0,05mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo9}</td>
                      <td className="right">13mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo10}</td>
                      <td className="right">32,2mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo11}</td>
                      <td className="right">0,05mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo12}</td>
                      <td className="right">118mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo13}</td>
                      <td className="right">0,0mg</td>
                    </tr>
                  </tbody>
                </Table>
              </FlagDown>
            </FlagDownShadow>
          </div>
          {/* <div
        className={`column is-4 is-paddingless is-marginless has-text-centered is-hidden-mobile ${
          smallBowl1Open ? '' : 'is-hidden'
        }`}
      >
        <FlagDownShadow>
          <FlagDown>
            <Table>
              <tbody>
                <tr>
                  <td className="left">{tabela.campo1}</td>
                  <td className="right">488kj</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo2}</td>
                  <td className="right">115kcal</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo3}</td>
                  <td className="right">1,2g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo4}</td>
                  <td className="right">5,6g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo5}</td>
                  <td className="right">0,0g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo6}</td>
                  <td className="right">22,5g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo7}</td>
                  <td className="right">18g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo8}</td>
                  <td className="right">32,2mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo9}</td>
                  <td className="right">0,05mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo10}</td>
                  <td className="right">0,0mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo11}</td>
                  <td className="right">118mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo12}</td>
                  <td className="right">13mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo13}</td>
                  <td className="right">6,3g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo14}</td>
                  <td className="right">0,06mg</td>
                </tr>
              </tbody>
            </Table>
          </FlagDown>
        </FlagDownShadow>
      </div>
      <div
        className={`column is-4 is-paddingless is-marginless has-text-centered is-hidden-mobile ${
          smallBowl1Open ? '' : 'is-hidden'
        }`}
      >
        <FlagDownShadow>
          <FlagDown>
            <Table>
              <tbody>
                <tr>
                  <td className="left">{tabela.campo1}</td>
                  <td className="right">488kj</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo2}</td>
                  <td className="right">115kcal</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo3}</td>
                  <td className="right">1,2g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo4}</td>
                  <td className="right">5,6g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo5}</td>
                  <td className="right">0,0g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo6}</td>
                  <td className="right">22,5g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo7}</td>
                  <td className="right">18g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo8}</td>
                  <td className="right">32,2mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo9}</td>
                  <td className="right">0,05mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo10}</td>
                  <td className="right">0,0mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo11}</td>
                  <td className="right">118mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo12}</td>
                  <td className="right">13mg</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo13}</td>
                  <td className="right">6,3g</td>
                </tr>
                <tr>
                  <td className="left">{tabela.campo14}</td>
                  <td className="right">0,06mg</td>
                </tr>
              </tbody>
            </Table>
          </FlagDown>
        </FlagDownShadow>
      </div> */}
          <div className="column is-12">
            <div
              className={`column is-12 has-text-centered is-hidden-mobile ${
                smallBowl1Open ? '' : 'is-hidden'
              }`}
            >
              <Button to="/" type="onclick" onClick={closeSmallBowl1}>
                {/* Ocultar tabelas */}
                {Context.languages.tabelaHide}
                <i className="material-icons icon-align">keyboard_arrow_up</i>
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className="column is-12 has-text-centered is-hidden-mobile">
            <img src={pote3} alt="" style={{ height: '237px' }} />
          </div>
          <div className="column is-12 is-paddingless is-marginless has-text-centered is-hidden-mobile">
            <H2>{Context.languages.nomesProdutos.banana}</H2>
            <H4>320 g</H4>
          </div>
          <div
            className={`column is-12 has-text-centered is-hidden-mobile ${
              !smallBowl2Open ? '' : 'is-hidden'
            }`}
          >
            <Button to="/" type="onclick" onClick={openSmallBowl2}>
              {/* Exibir tabelas nutricionais */}
              {Context.languages.tabela}
              <i className="material-icons icon-align">keyboard_arrow_down</i>
            </Button>
          </div>
          <div
            className={`column is-12 is-paddingless is-marginless has-text-centered is-hidden-mobile ${
              smallBowl2Open ? '' : 'is-hidden'
            }`}
          >
            <FlagDownShadow>
              <FlagDown>
                <Table>
                  <tbody>
                    <tr>
                      <td className="left">{tabela.campo1}</td>
                      <td className="right">514kj / 122kcal</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo2}</td>
                      <td className="right">3,6g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo3}</td>
                      <td className="right">0,9g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo4}</td>
                      <td className="right">19,4g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo5}</td>
                      <td className="right">14g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo6}</td>
                      <td className="right">4,4g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo7}</td>
                      <td className="right">0,9g</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo8}</td>
                      <td className="right">0,05mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo9}</td>
                      <td className="right">13mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo10}</td>
                      <td className="right">32,2mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo11}</td>
                      <td className="right">0,05mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo12}</td>
                      <td className="right">118mg</td>
                    </tr>
                    <tr>
                      <td className="left">{tabela.campo13}</td>
                      <td className="right">0,0mg</td>
                    </tr>
                  </tbody>
                </Table>
              </FlagDown>
            </FlagDownShadow>
          </div>
          <div className="column is-12">
            <div
              className={`column is-12 has-text-centered is-hidden-mobile ${
                smallBowl2Open ? '' : 'is-hidden'
              }`}
            >
              <Button to="/" type="onclick" onClick={closeSmallBowl2}>
                {/* Ocultar tabelas */}
                {Context.languages.tabelaHide}
                <i className="material-icons icon-align">keyboard_arrow_up</i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
