import React from 'react';
import PropTypes from 'prop-types';

import { StyledArrowType1, StyledArrowType2 } from './styles';

function Arrow({ type, color, height = '100' }) {
  if (type === 1) {
    return (
      <StyledArrowType1>
        <svg
          style={{ fill: color }}
          fill={color}
          width="100%"
          height={height}
          viewBox="0 0 100 102"
          preserveAspectRatio="none"
        >
          <path d="M0 0 L50 100 L100 0 Z" />
        </svg>
      </StyledArrowType1>
    );
  }

  if (type === 2) {
    return (
      <StyledArrowType2>
        <svg
          style={{ fill: color }}
          width="100%"
          // height="100"
          height={height}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="
              M 0 0
              L 0 100
              L 100 100
              L 100 0
              L 50 100
              L 0 0
              Z"
          />
        </svg>
      </StyledArrowType2>
    );
  }

  return '';
}

Arrow.propTypes = {
  type: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Arrow;
