import React from 'react';
import PropTypes from 'prop-types';

import { StyledIcon } from './styles';

function Icon({ src, limitwidth, center }) {
  return (
    <StyledIcon center={center} limitwidth={limitwidth}>
      <img src={src} alt="" />
    </StyledIcon>
  );
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  limitwidth: PropTypes.number.isRequired,
  center: PropTypes.bool,
};

Icon.defaultProps = {
  center: false,
};

export default Icon;
