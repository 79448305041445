import React from 'react';
import PropTypes from 'prop-types';

import { StyledPageTitle } from './styles';

function PageTitle({ color, center, uppercase, children, fontsize }) {
  return (
    <StyledPageTitle
      color={color}
      center={center}
      uppercase={uppercase}
      fontsize={fontsize}
    >
      {children}
    </StyledPageTitle>
  );
}

PageTitle.propTypes = {
  color: PropTypes.string.isRequired,
  center: PropTypes.bool,
  uppercase: PropTypes.bool,
  fontsize: PropTypes.number,
  children: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {
  center: false,
  uppercase: false,
  fontsize: 60,
};

export default PageTitle;
