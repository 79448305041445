import styled from 'styled-components';
import {
  Navbar,
  NavbarBurger,
  NavbarMenu,
  NavbarItem,
  NavbarLink,
  NavbarDropdown,
  // NavbarDivider,
} from 'bloomer';
// import { NavLink } from 'react-router-dom';

export const StyledNavbar = styled(Navbar)`
  // background: #563e8e !important;
  background: #fcfaec !important;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  height: 90px;
`;

export const StyledLogo = styled(NavbarItem)`
  .logo {
    padding-top: 0px !important;

    img {
      padding-top: 130px;
      max-height: none !important;
      z-index: 1;
    }
  }

  @media (max-width: 1023px) {
    padding-top: 0px !important;

    img {
      padding-top: 0px !important;
      height: 110px !important;
    }
  }
`;

export const StyledNavbarMenu = styled(NavbarMenu)`
  .active {
    background-color: transparent !important;
    // border-top: 4px solid transparent;
    // border-bottom: 4px solid #563e8e;
  }

  @media (max-width: 1023px) {
    margin-top: -38px;
    background: #fcfaec !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .active {
      background-color: transparent !important;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
`;

export const StyledNavbarBurger = styled(NavbarBurger)`
  color: #563e8e !important;
  height: 90px !important;
`;

export const StyledNavItem = styled(NavbarItem)`
  font-family: LLCharlotte;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #563e8e !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  &.active {
    text-decoration: underline;
  }

  &:hover {
    background: #eae8db !important;
  }

  &.last {
    margin-left: 50px;
  }

  &.lang {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  &.lang-first {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (min-width: 1024px) and (max-width: 1215px) {
    padding-left: 10px !important;
    padding-right: 10px !important;

    &.lang {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
    &.lang-first {
      padding-right: 2px !important;
    }
    &.last {
      margin-left: 0px;
    }
  }
`;

export const StyledNavbarDropdown = styled(NavbarDropdown)`
  font-family: LLCharlotte;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #563e8e !important;
  background-color: #fcfaec !important;
`;

export const StyledNavbarLink = styled(NavbarLink)`
  font-family: LLCharlotte;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #563e8e !important;
  background-color: #fcfaec !important;
  ::after {
    border-left: 3px solid #563e8e !important;
    border-bottom: 3px solid #563e8e !important;
  }

  /* .navbar-link::after {
    border: 1px solid #563e8e !important;
  } */
  &:hover {
    background: #eae8db !important;
  }

  /* &:last-child { */
  &.last {
    margin-left: 0px;
    /* background: transparent !important; */
  }

  @media (min-width: 1024px) and (max-width: 1215px) {
    /* padding-left: 20px !important;
    padding-right: 20px !important; */
    &.last {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  @media (max-width: 1023px) {
    /* padding: 20px 20px !important; */
    text-align: center;

    &.last {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
`;
