/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledInput } from './styles';

function Input({
  id,
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  onFocus,
  disabled,
}) {
  return (
    <StyledInput>
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <input
        className="input"
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
      />
    </StyledInput>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  disabled: false,
};

export default Input;
