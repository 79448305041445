import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink, StyledNavLink } from './styles';

function Link({ to, color, hovercolor, center, children }) {
  return (
    <StyledLink center={center}>
      <StyledNavLink to={to} color={color} hovercolor={hovercolor}>
        {children}
      </StyledNavLink>
    </StyledLink>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hovercolor: PropTypes.string.isRequired,
  center: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Link.defaultProps = {
  center: false,
};

export default Link;
