/* eslint-disable camelcase */
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { lightColors, Link } from 'react-floating-action-button';
import { Arrow, Icon, Text, Title } from '../../components';
// import SimpleAccordion from '../../components/Accordionn';
import LanguageContext from '../../components/LanguageContext/context';
import image8 from '../../images/icon_zigzag4.png';
import image1_fr from '../../images/montagem_frances.png';
import image1 from '../../images/montagem_ornamentos.png';
import image1_en from '../../images/montagem_ornamentos_en.png';
import image1_es from '../../images/montagem_ornamentos_es.png';
import image4 from '../../images/selo1.png';
import image6 from '../../images/selo3.png';
import image7 from '../../images/selo4.png';
import image10 from '../../images/seta.png';
import img_video1 from '../../images/video_1_imagem.png';
import img_video2 from '../../images/video_2_imagem.png';
import video2 from '../../videos/amazing-amazonia.m4v';
import video1 from '../../videos/nossa-missao.m4v';
// import Contact from '../Contact';
import Products from '../Products';
import {
  // AquiTem,
  Container,
  Section1,
  Section2,
  Section3,
  Section4,
  Section7,
  Section8,
  StyledFloat,
  VideoSection,
} from './styles';

export default function Home() {
  return (
    <>
      <LanguageContext.Consumer>
        {({ languages }) => (
          <Container>
            <StyledFloat>
              <Link
                href="#top"
                tooltip={languages.topo}
                icon="arrow-up"
                styles={{
                  backgroundColor: '#71D1B4',
                  color: lightColors.white,
                }}
              >
                <img src={image10} alt="" />
              </Link>
            </StyledFloat>
            <Section1>
              <div className="container">
                <div className="section">
                  <div className="columns">
                    <div className="column has-text-centered">
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={500}
                      >
                        {languages.language === 'pt' && (
                          <img className="main-img" src={image1} alt="" />
                        )}
                        {languages.language === 'en' && (
                          <img className="main-img" src={image1_en} alt="" />
                        )}
                        {languages.language === 'es' && (
                          <img className="main-img" src={image1_es} alt="" />
                        )}
                        {languages.language === 'fr' && (
                          <img className="main-img" src={image1_fr} alt="" />
                        )}
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
              <div className="is-hidden-desktop">
                <Arrow type={1} height="50" color="#fcfaec" />
              </div>
              <div className="is-hidden-touch">
                <Arrow type={1} color="#fcfaec" />
              </div>
            </Section1>
            {/* ---------------------------------------------------------------------------- */}
            <Section2>
              <div className="container" id="a-amazing">
                <div className="section">
                  <div className="columns">
                    <div className="column">
                      <ScrollAnimation animateOnce animateIn="fadeIn">
                        <Title fontFamily="Dolato" color="#4fc6a2" center>
                          {languages.nossaHistoria}
                        </Title>
                      </ScrollAnimation>
                      <div className="limit-width-text margin-text">
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                          <Text color="#fcfaec">{languages.texto1}</Text>
                          <Text color="#fcfaec">{languages.texto2}</Text>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section2>
            {/* ---------------------------------------------------------------------------- */}
            <div className="container">
              <div className="section">
                <div className="columns">
                  <div className="column">
                    <video
                      className="afterglow"
                      id="nossamissao"
                      width="848"
                      height="480"
                      src={video1}
                      poster={img_video1}
                    >
                      <track kind="captions" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------------------------------------------------------------- */}
            <Section3>
              <div className="background" id="produtos">
                <div className="container">
                  <div className="section">
                    <div className="columns is-multiline">
                      {/* <div className="column margin-title"> */}
                      <div className="column">
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                          <Title color="#4fc6a2" center>
                            {languages.produtos}
                          </Title>
                        </ScrollAnimation>
                      </div>
                      <div className="column is-12">
                        <Text color="#563e8e">{languages.texto3}</Text>
                        <Text color="#563e8e">{languages.texto4}</Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section3>
            {/* ---------------------------------------------------------------------------- */}
            <Section4>
              <div className="is-hidden-desktop">
                <Arrow type={1} height="50" color="#fcfaec" />
              </div>
              <div className="is-hidden-touch">
                <Arrow type={1} color="#fcfaec" />
              </div>
              <div className="container margin-section">
                <div className="section">
                  <div className="columns is-multiline is-centered">
                    <Products />
                  </div>
                </div>
              </div>
              <div className="is-hidden-desktop">
                <Arrow type={1} height="50" color="#472e6d" />
              </div>
              <div className="is-hidden-touch">
                <Arrow type={1} color="#472e6d" />
              </div>
            </Section4>
            {/* ---------------------------------------------------------------------------- */}
            <Section8>
              <div className="container" id="sustentabilidade">
                <div className="section">
                  <div className="columns">
                    <div className="column">
                      <div className="margin-title">
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                          <Title color="#4fc6a2" center>
                            {languages.sustentabilidade}
                          </Title>
                        </ScrollAnimation>
                      </div>
                      <div className="limit-width-text margin-text">
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                          <Text color="#563e8e">{languages.texto7}</Text>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section8>
            <VideoSection>
              <div className="container">
                <div className="section">
                  <div className="columns">
                    <div className="column">
                      <video
                        className="afterglow"
                        id="amazonia"
                        width="848"
                        height="480"
                        src={video2}
                        poster={img_video2}
                      >
                        <track kind="captions" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </VideoSection>
            {/* ---------------------------------------------------------------------------- */}

            {/* <AquiTem>
              <SimpleAccordion />
            </AquiTem> */}
            {/* ---------------------------------------------------------------------------- */}
            {/* }
            <div id="contacto">
              <Contact />
            </div>

            {/* ---------------------------------------------------------------------------- */}
            <Section7 zigzag={image8}>
              <div className="container">
                <div className="section">
                  <div className="columns is-multiline">
                    <div className="column">
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={300}
                      >
                        <Icon src={image7} limitwidth={145} center />
                      </ScrollAnimation>
                    </div>
                    <div className="column">
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={600}
                      >
                        <Icon src={image6} limitwidth={145} center />
                      </ScrollAnimation>
                    </div>
                    <div className="column">
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={1200}
                      >
                        <Icon src={image4} limitwidth={145} center />
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
              <div className="zigzag" />
            </Section7>
          </Container>
        )}
      </LanguageContext.Consumer>
    </>
  );
}
