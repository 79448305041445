import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const StyledLink = styled.div`
  color: ${props => props.color};
  font-size: 17px;
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: ${props => (props.center ? 'center' : 'none')};
`;

export const StyledNavLink = styled(NavLink)`
  color: ${props => props.color};

  &:hover {
    color: ${props => props.hovercolor};
  }
`;
