import styled from 'styled-components';

export const StyledFooter = styled.footer`
  .zigzag {
    background-repeat: repeat-x;
    height: ${props => (props.type === 0 ? '31px' : '45px')};
    width: 100%;
    background-image: url(${props => props.zigzag});
  }

  a {
    text-align: center;
  }
  img {
    margin: 0 auto;
  }

  .background {
    background: #fcfaec;
    padding-top: 30px;
  }

  .background-bottom {
    background-color: rgba(86, 62, 142, 0.06);

    /* height: 77px; */
    /* padding-top: 30px; */
  }

  .no-break {
    white-space: nowrap;
  }

  .box-custom {
    h4 {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: 1.26px;
      color: #563e8e;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    ul li {
      margin-bottom: 25px;
    }

    .margin-top-email {
      margin-top: 69px !important;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: 1.26px;
      color: #563e8e;
      white-space: nowrap;
    }

    a {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: 0.93px;
      text-align: left;
      color: #563e8e;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .social {
    a {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }

    svg {
      background: #4fc6a2;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background: #563e8e;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1215px) {
    .social {
      /* margin-left: 30px !important; */
    }
  }
  .logo {
    img {
      max-width: 180px;
      /* margin-top: 40px; */
    }
  }
`;
