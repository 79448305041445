import styled from 'styled-components';

export const StyledIcon = styled.div`
  text-align: ${props => (props.center ? 'center' : 'none')} !important;

  img {
    max-width: ${props => props.limitwidth}px;
    width: 100%;
  }

  @media (max-width: 1023px) {
    img {
      max-width: 150px;
    }
  }
`;
