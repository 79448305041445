export default {
  language: 'es',
  amazing: 'Amazing',
  nossaHistoria: 'Nuestra Historia',
  produtos: 'Productos',
  beneficios: 'Beneficios',
  sustentabilidade: 'Sostenibilidad',
  contato: 'Contacto',
  texto1:
    'Amazing Acaí nace de la idea entre dos hermanos empresarios, que han tenido éxito en Brasil trabajando con açai durante más de 20 años, con las marcas Tia Augusta y Açaí Aju. Con el fin de ampliar los límites de su negocio, eligen Portugal como su segunda casa a finales de 2018.',
  texto2:
    'Preocupado por promover una mezcla de açaí de muy alta calidad, con sabor y valor nutricional únicos, Amazing Açaí instala su primera unidad piloto en suelo europeo, ubicada en el Polígono Industrial de Vendas Novas, distrito de Évora. Impulsado por el marco de apoyo portugués PT2020, Amazing Açaí invertirá en la expansión de su unidad industrial en Portugal.',
  texto3:
    'Nuestro açaí no tiene, en su fórmula, gluten y lactosa siendo un producto 100% vegano. Realizamos un seguimiento estricto de nuestros proveedores de frutas para controlar la calidad e higiene de la materia prima.',
  texto4:
    'Nuestra pulpa se considera Premium (o Especial) donde la concentración de sólidos solubles varía entre 14% y 18% por 100 gramos de producto. En comparación con otras pulpas - Popular (8% y 10%) y Medio (11% y 13%) es sin duda la más completa y rica en nutrientes, con un sabor fuerte y único, sugerido para recetas más complejas y para aquellos que buscan un producto de calidad superior.',
  texto5:
    'Acai es una fruta nativa de la Amazonía, donde se ha consumido durante miles de años. Se considera un superalimento porque es una fuente importante de proteínas, fibras, calcio, potasio, vitaminas A, C, E y del complejo B. Es rico en polifenoles, especialmente de la clase de antocianinas, responsable de su alto poder antioxidante, estimulando las funciones cerebrales, luchando contra los radicales libres y contra el envejecimiento.',
  texto6:
    'Esta fruta también es conocida por sus altas tasas de ácidos grasos esenciales (omegas 6 y 9), que ayudan a reducir el colesterol LDL, y aumentar el colesterol HDL, contribuyendo a la prevención de enfermedades cardiovasculares y arteriosclerosis. Acai es sinónimo de energía y vitalidad.',
  texto7:
    'La materia prima utilizada en nuestra mezcla, proviene de la Amazonía, más precisamente del estado de Pará, Brasil. Amazing fortalece la economía local al priorizar a los pequeños productores.',
  contatoText: {
    titulo: 'Contacto',
    subtitulo: 'Conviértase en un distribuidor Amazing',
    nome: 'Nombre *',
    email: 'Email *',
    assunto: 'Tema *',
    mensagem: 'Mensaje *',
    botao: 'Enviar',
    validador: 'Todos los campos son obligatorios!',
    sucesso: 'Mensaje enviado con éxito!',
    erro: 'Hubo un error al enviar el mensaje. ¡Inténtalo de nuevo!',
    sede: 'Sede de la Empresa',
    industria: 'Industria',
  },
  distribuidores: {
    titulo: 'Nuestros Distribuidores',
  },
  tabela: 'Ver datos nutricionales',
  tabelaHide: 'Ocultar datos nutricionales',
  tabelaDados: {
    campo1: 'Valor energético',
    campo2: 'Lípidos',
    campo3: 'De los cuales saturados',
    campo4: 'Carbohidratos',
    campo5: 'De los cuales azúcares',
    campo6: 'Fibra dietética',
    campo7: 'Proteínas',
    campo8: 'Sal',
    campo9: 'Vitamina C',
    campo10: 'Potasio',
    campo11: 'Hierro',
    campo12: 'Calcio',
    campo13: 'Gluten',
  },
  nomesProdutos: {
    original: 'Acai Original',
    premium: 'Acai Original',
    banana: 'Acai Original',
    frutas: 'Acai con Frutos Rojos',
  },
  topo: '¡Parte superior!',
};
