import React, { Component } from 'react';
import { Container, NavbarBrand, NavbarEnd, NavbarDivider } from 'bloomer';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo_amazing_menu.png';

import LanguageContext from '../LanguageContext/context';

import {
  StyledNavbar,
  StyledLogo,
  StyledNavItem,
  StyledNavbarBurger,
  StyledNavbarMenu,
  // StyledNavbarLink,
  // StyledNavbarDropdown,
} from './styles';

class Header extends Component {
  state = {
    isActive: false,
  };

  onClickNav = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const { isActive } = this.state;

    return (
      <header id="top">
        <StyledNavbar>
          <LanguageContext.Consumer>
            {({ languages, toggleEn, togglePt, toggleEs, toggleFr }) => (
              <Container>
                <NavbarBrand>
                  <StyledLogo>
                    <NavLink
                      className="navbar-item logo animated fadeInDown"
                      delay={500}
                      to="/"
                    >
                      <img src={logo} alt="" />
                    </NavLink>
                  </StyledLogo>
                  <StyledNavbarBurger
                    isActive={isActive}
                    onClick={this.onClickNav}
                  />
                </NavbarBrand>
                <StyledNavbarMenu isActive={isActive} onClick={this.onClickNav}>
                  <NavbarEnd>
                    <StyledNavItem
                      className="navbar-item item"
                      href="#a-amazing"
                    >
                      {languages.amazing}
                    </StyledNavItem>
                    <StyledNavItem
                      className="navbar-item item"
                      href="#produtos"
                    >
                      {languages.produtos}
                    </StyledNavItem>
                    {/*
                    <StyledNavItem
                    className="navbar-item item"
                    href="#beneficios"
                  >
                    {languages.beneficios}
                  </StyledNavItem>
                    */}
                    <StyledNavItem
                      className="navbar-item item"
                      href="#sustentabilidade"
                    >
                      {languages.sustentabilidade}
                    </StyledNavItem>
                    {/* <StyledNavItem
                      className="navbar-item item"
                      href="#contacto"
                    >
                      {languages.contato}
                    </StyledNavItem> */}
                    <NavbarDivider />
                    {languages.language === 'pt' ? (
                      <StyledNavItem
                        className="lang-first active"
                        href="#top"
                        onClick={() => togglePt()}
                      >
                        PT
                      </StyledNavItem>
                    ) : (
                      <StyledNavItem
                        className="lang-first"
                        href="#top"
                        onClick={() => togglePt()}
                      >
                        PT
                      </StyledNavItem>
                    )}
                    {languages.language === 'en' ? (
                      <StyledNavItem
                        className="lang active"
                        href="#top"
                        onClick={() => toggleEn()}
                      >
                        EN
                      </StyledNavItem>
                    ) : (
                      <StyledNavItem
                        className="lang"
                        href="#top"
                        onClick={() => toggleEn()}
                      >
                        EN
                      </StyledNavItem>
                    )}
                    {languages.language === 'es' ? (
                      <StyledNavItem
                        className="lang active"
                        href="#top"
                        onClick={() => toggleEs()}
                      >
                        ES
                      </StyledNavItem>
                    ) : (
                      <StyledNavItem
                        className="lang"
                        href="#top"
                        onClick={() => toggleEs()}
                      >
                        ES
                      </StyledNavItem>
                    )}
                    {languages.language === 'fr' ? (
                      <StyledNavItem
                        className="lang active"
                        href="#top"
                        onClick={() => toggleFr()}
                      >
                        FR
                      </StyledNavItem>
                    ) : (
                      <StyledNavItem
                        className="lang"
                        href="#top"
                        onClick={() => toggleFr()}
                      >
                        FR
                      </StyledNavItem>
                    )}
                  </NavbarEnd>
                </StyledNavbarMenu>
              </Container>
            )}
          </LanguageContext.Consumer>
        </StyledNavbar>
      </header>
    );
  }
}

export default Header;
