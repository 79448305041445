import React from 'react';
import PropTypes from 'prop-types';

import { StyledSubtitle } from './styles';

function Subtitle({ color, center, uppercase, children }) {
  return (
    <StyledSubtitle color={color} center={center} uppercase={uppercase}>
      {children}
    </StyledSubtitle>
  );
}

Subtitle.propTypes = {
  color: PropTypes.string.isRequired,
  center: PropTypes.bool,
  uppercase: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Subtitle.defaultProps = {
  center: false,
  uppercase: false,
};

export default Subtitle;
