export default {
  language: 'pt',
  amazing: 'Amazing',
  nossaHistoria: 'Nossa História',
  produtos: 'Produtos',
  beneficios: 'Benefícios',
  sustentabilidade: 'Sustentabilidade',
  contato: 'Contacto',
  texto1:
    'A Amazing Açaí surge da idéia entre dois irmãos empresários, que fazem sucesso no Brasil com açaí há mais de 20 anos, com as marcas Tia Augusta e Açaí Aju. Com intuito de ampliar as fronteiras de seus negócios, escolhem Portugal como sua segunda casa ao final de 2018.',
  texto2:
    'Preocupados em promover um blend de açaí de altíssima qualidade, com sabor único e valor nutricional, a Amazing Açaí instala a sua primeira unidade piloto em solo europeu, situada no Parque Industrial de Vendas Novas, distrito de Évora. Impulsionada pelo quadro de apoio português PT2020, a Amazing Açaí investirá na ampliação da sua unidade industrial em Portugal.',
  texto3:
    'Nosso açaí não possui, em sua fórmula, glúten e lactose sendo um produto 100% vegan. Rastreamos, rigorosamente, nossos fornecedores de fruto, a fim de controlar a qualidade e higiene da matéria prima.',
  texto4:
    'Nossa polpa é considerada Premium (ou Especial) onde a concentração de sólidos solúveis varia entre 14% e 18% por 100 gramas de produto. Comparada com as outras polpas – Popular (8% e 10%) e Média (11% a 13%), é sem dúvidas a mais completa e rica em nutrientes, com sabor forte e único, sugerida para receitas mais complexas e para quem busca um produto de qualidade superior.',
  texto5:
    'O açaí é um fruto em baga, nativo da Amazônia, onde é consumido há milhares de anos. É considerado um superalimento por ser uma importante fonte de proteínas, fibras, cálcio, potássio, vitaminas A, C, E, e do complexo B. É rico em polifenóis, principalmente da classe das antocianinas, responsáveis pelo seu alto poder antioxidante, estimulando as funções cerebrais, combatendo os radicais livres e o envelhecimento precoce.',
  texto6:
    'Esta fruta também é conhecida por seus altos índices de ácidos graxos essenciais (ômegas 6 e 9), que auxiliam na redução do colesterol LDL, e no aumento do colesterol HDL, contribuindo na prevenção de doenças cardiovasculares e da arteriosclerose. O açaí é sinônimo de energia e vitalidade.',
  texto7:
    'A matéria prima utilizada é oriunda da Floresta Amazônica, mais precisamente do estado do Pará, Brasil. A Amazing fortalece a economia local, priorizando pequenos produtores.',
  contatoText: {
    titulo: 'Contacto',
    subtitulo: 'Seja um Distribuidor amazing!',
    nome: 'Nome *',
    email: 'Email *',
    assunto: 'Assunto *',
    mensagem: 'Mensagem *',
    botao: 'Enviar',
    validador: 'Todos os campos são obrigatórios!',
    sucesso: 'Mensagem enviada com sucesso!',
    erro: 'Ocorreu um erro ao enviar a mensagem. Tente novamente!',
    sede: 'Sede da Empresa',
    industria: 'Unidade Produtiva',
  },
  distribuidores: {
    titulo: 'Nossos Distribuidores',
  },
  tabela: 'Exibir Tabelas Nutricionais',
  tabelaHide: 'Ocultar Tabelas',
  tabelaDados: {
    campo1: 'Valor Energético',
    campo2: 'Lipídios',
    campo3: 'Dos Quais Saturados',
    campo4: 'Hidratos de Carbono',
    campo5: 'Dos Quais Açúcares',
    campo6: 'Fibra Alimentar',
    campo7: 'Proteínas',
    campo8: 'Sal',
    campo9: 'Vitamina C',
    campo10: 'Potássio',
    campo11: 'Ferro',
    campo12: 'Cálcio',
    campo13: 'Glúten',
  },
  nomesProdutos: {
    original: 'Açaí Original',
    premium: 'Açaí Original',
    banana: 'Açaí Original',
    frutas: 'Açaí com Frutas Vermelhas',
  },
  topo: 'Ir para o topo!',
};
