import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
  StyledButtonNavLink,
  StyledButtonSubmit,
  StyledButtonOnClick1,
} from './styles';

function Button({ type, to, children, isLoading, onClick }) {
  if (type === 'navlink') {
    return (
      <StyledButton>
        <StyledButtonNavLink to={to}>{children}</StyledButtonNavLink>
      </StyledButton>
    );
  }
  if (type === 'submit') {
    return (
      <StyledButton>
        <StyledButtonSubmit
          className={isLoading ? 'button is-loading' : 'button'}
          to={to}
          type="submit"
        >
          {children}
        </StyledButtonSubmit>
      </StyledButton>
    );
  }
  if (type === 'onclick') {
    return (
      <StyledButtonOnClick1 onClick={onClick}>{children}</StyledButtonOnClick1>
    );
  }

  return '';
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  to: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  to: '',
  isLoading: false,
};

export default Button;
