import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import StickyFooter from 'react-sticky-footer';
import { StyledFooter } from '../styles';
import image4 from '../../../images/marcas.png';
import file from '../../../files/Fabio&Mirian_Ficha de Projeto_INT.pdf';

class Sticky extends Component {
  render() {
    // const { type } = this.state;

    return (
      <>
        <StickyFooter
          bottomThreshold={1}
          normalStyles={{
            backgroundColor: '#EBE7F2',
            width: '100%',
          }}
          stickyStyles={{
            backgroundColor: '#EBE7F2',
            width: '100%',
            zIndex: '3',
          }}
        >
          <StyledFooter>
            <div className="background-bottom">
              <div className="columns is-centered has-text-centered is-marginless">
                <div className="column is-12" style={{ paddingBottom: 0 }}>
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src={image4} alt="" style={{ width: "100%", maxWidth: "547px" }} />
                  </a>
                </div>
              </div>
            </div>
          </StyledFooter>
        </StickyFooter>
      </>
    );
  }
}

export default withRouter(Sticky);
