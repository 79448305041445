import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import { StyledProduct } from './styles';

function Product({ image, name, description }) {
  return (
    <StyledProduct>
      <div className="center-image">
        <img src={image} alt="" />
      </div>
      <h1>{name}</h1>
      <div className="limit-width-text">
        <Text color="#563e8e" center>
          {description}
        </Text>
      </div>
    </StyledProduct>
  );
}

Product.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Product;
