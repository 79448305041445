import styled from 'styled-components';

export const StyledText = styled.p`
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: ${props => (props.bold ? '700' : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${props => props.color};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  text-align: ${props => (props.center ? 'center' : 'justify')};
  margin-top: 20px !important;

  &:first-child {
    margin-top: 0px !important;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
