import React from 'react';
import PropTypes from 'prop-types';

import { StyledText } from './styles';

function Text({ center, color, bold, uppercase, children }) {
  return (
    <StyledText color={color} center={center} bold={bold} uppercase={uppercase}>
      {children}
    </StyledText>
  );
}

Text.propTypes = {
  color: PropTypes.string.isRequired,
  center: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Text.defaultProps = {
  center: false,
  bold: false,
  uppercase: false,
};

export default Text;
