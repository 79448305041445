import styled from 'styled-components';

export const StyledSubtitle = styled.h2`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.color};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  text-align: ${props => (props.center ? 'center' : 'none')};
  margin-top: 40px;
`;
