import styled from 'styled-components';

export const StyledInput = styled.div`
  label {
    font-size: 25px;
    color: #4fc6a2;
    margin-bottom: 0px !important;
  }
  input {
    box-shadow: none;
    font-size: 14px;
    height: 45px;

    border: 2px solid #563e8e;
    background-color: #fcfaec;
  }
  input:hover {
    border-color: #563e8e;
  }
  input:focus,
  input:active {
    box-shadow: none;
  }
  input::placeholder {
    color: rgba(0, 0, 0, 0.38);
    opacity: 1;
  }
  input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
