import React from 'react';
import PropTypes from 'prop-types';

import { StyledAcaiSelector } from './styles';

function AcaiSelector({ color, center, uppercase, children }) {
  return (
    <StyledAcaiSelector color={color} center={center} uppercase={uppercase}>
      {children}
    </StyledAcaiSelector>
  );
}

AcaiSelector.propTypes = {
  color: PropTypes.string.isRequired,
  center: PropTypes.bool,
  uppercase: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

AcaiSelector.defaultProps = {
  center: false,
  uppercase: false,
};

export default AcaiSelector;
