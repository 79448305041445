import styled from 'styled-components';

export const Image = styled.img`
  margin-top: -200px;
  z-index: 2;
`;

export const H1 = styled.h1`
  font-family: Montserrat;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.98;
  text-align: center;
  color: #fcfaec;
  text-transform: uppercase;
`;

export const H2 = styled.h2`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #fcfaec;
  text-transform: uppercase;
`;

export const H3 = styled.h2`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.63px;
  text-align: center;
  color: #fcfaec;
  text-transform: uppercase;
`;

export const H4 = styled.h2`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #fcfaec;
  text-transform: uppercase;
`;

export const FlagDown = styled.div`
  width: 100%;
  padding-top: 70px;
  padding-bottom: 50px;
  background: #472e6d !important;
  color: #fcfaec !important;
  text-align: center;
  clip-path: polygon(
    50% 10%,
    100% 0,
    100% 90%,
    50% 100%,
    0 90%,
    0 0
  ) !important;

  /* @media (max-width: 768) {
    margin: 0 auto !important;
  } */
`;

export const FlagDownShadow = styled.div`
  filter: drop-shadow(-5px 5px 21px rgba(0, 0, 0, 0.16)) !important;
`;

export const Table = styled.table`
  color: #fcfaec !important;
  margin: 0 auto;
  thead,
  th,
  tr,
  td,
  tbody {
    font-family: Montserrat;
    font-size: 15px;
    color: #fcfaec !important;
    .left {
      text-align: right !important;
    }
    .right {
      text-align: left !important;
    }
  }
  th,
  td {
    width: 50% !important;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  th {
    padding-bottom: 15px;
    font-size: 17px;
  }
  td {
    padding-bottom: 5px;
  }
`;

export const FlagRight = styled.div`
  margin-left: -100px;
  /* margin-right: 70px; */
  margin-top: -185px;
  @media (max-width: 930px) {
    margin-left: -20px;
  }
  @media (min-width: 930px) and (max-width: 1215px) {
    margin-left: -50px;
  }
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #472e6d !important;
  color: #fcfaec !important;
  text-align: center;
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0 50%, 0 0);
`;

export const FlagRightShadow = styled.div`
  margin-left: -50px;
  margin-top: -185px;

  z-index: -1;
  filter: drop-shadow(-5px 5px 21px rgba(0, 0, 0, 0.16)) !important;
`;
