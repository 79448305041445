import styled from 'styled-components';

export const StyledProduct = styled.div`
  .center-image {
    text-align: center !important;
  }

  img {
    max-width: 455px;
    width: 100%;
  }

  h1 {
    font-family: 'Montserrat';
    font-size: 27px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #563e8e;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .limit-width-text {
    margin: 0 auto;
    max-width: 240px;
  }
`;
