import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { NavLink, withRouter } from 'react-router-dom';

import { StyledFooter } from './styles';

import LanguageContext from '../LanguageContext/context';

import image1 from '../../images/logo_amazing.png';
import image2 from '../../images/icon_zigzag3.png';
import image3 from '../../images/icon_zigzag4.png';
// import image4 from '../../images/marcas.png';
// import file from '../../files/Fabio&Mirian_Ficha de Projeto_INT.pdf';

class Footer extends Component {
  state = {
    type: 0,
  };

  render() {
    const { type } = this.state;

    return (
      <LanguageContext.Consumer>
        {({ languages }) => (
          <StyledFooter zigzag={type === 0 ? image2 : image3} type={type}>
            <div className="background">
              <div className="container">
                <div className="section">
                  <div className="columns is-multiline is-vcentered">
                    <div className="column is-12-tablet is-7-desktop ">
                      <div className="columns is-multiline is-paddingless is-marginless">
                        <div className="column is-12-tablet is-5-desktop">
                          <div className="box-custom">
                            <h4>{languages.contato}</h4>
                            <p style={{ marginBottom: '10px' }}>
                              {/* +351 265.805.417 */}
                            </p>
                            {/* <p>+351 926.742.824</p> */}
                            <p>+351 960.257.172</p>
                          </div>
                        </div>
                        <div className="column is-12-tablet is-7-desktop">
                          <div className="box-custom">
                            <h4>E-mail</h4>
                            <a href="mailto:comercial@amazingfruits.net">
                              comercial@amazingfruits.net
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-12-tablet is-2-desktop ">
                      <div className="social no-break">
                        <a href="https://www.instagram.com/acaiamazing/">
                          <FaInstagram fill="#fff" size={60} />
                        </a>
                        <a href="https://www.facebook.com/acaiamazing">
                          <FaFacebookF fill="#fff" size={60} />
                        </a>
                      </div>
                    </div>
                    <div className="column is-12-tablet is-3-desktop">
                      <div className="logo">
                        <NavLink to="/">
                          <img src={image1} alt="" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="background-bottom">
              <div className="columns is-centered has-text-centered is-marginless">
                <div className="column is-12">
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <img src={image4} alt="" />
                  </a>
                </div>
              </div>
            </div> */}
          </StyledFooter>
        )}
      </LanguageContext.Consumer>
    );
  }
}

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Footer.defaultProps = {
  location: {
    pathname: '',
  },
};

export default withRouter(Footer);
