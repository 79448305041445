import styled from 'styled-components';

export const StyledTextArea = styled.div`
  label {
    font-size: 25px;
    color: #4fc6a2;
    margin-bottom: 0px !important;
  }
  textarea {
    box-shadow: none;
    font-size: 14px;
    height: 45px;
    border: 2px solid #563e8e;
    background-color: #fcfaec;
  }
  textarea:hover {
    border-color: #563e8e;
  }
  textarea:focus,
  textarea:active {
    box-shadow: none;
  }
  textarea::placeholder {
    color: rgba(0, 0, 0, 0.38);
    opacity: 1;
  }
  textarea:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  textarea::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  textarea[type='number']::-webkit-inner-spin-button,
  textarea[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
