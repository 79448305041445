/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Arrow, Title } from '../index';
import { BannerImage, WhiteSection } from './styles';

function Banner({ title, backgroundImage, children }) {
  return (
    <>
      <BannerImage bgUrl={backgroundImage}>
        <div className="container">
          <div className="columns">
            <div className="column padding-title">
              {/* <TitleBeneficios> */}
              <Title
                fontFamily="LLCharlotte"
                fontSize={85}
                color="#ffffff"
                center
                uppercase
              >
                {title}
              </Title>
              {/* </TitleBeneficios> */}
            </div>
          </div>
        </div>
      </BannerImage>
      <WhiteSection>
        <Arrow type={2} color="#ffffff" />
        {children && (
          <>
            {children}
            <Arrow type={1} color="#fcfaec" />
          </>
        )}
      </WhiteSection>
    </>
  );
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Banner;
