import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import { StyledInfo } from './styles';

function Info({ image, name, description }) {
  return (
    <StyledInfo>
      <h1>{name}</h1>
      <div className="center-image margin-image">
        <img src={image} alt="" />
      </div>
      <div className="limit-width-text">
        <Text color="#563e8e" center>
          {description}
        </Text>
      </div>
    </StyledInfo>
  );
}

Info.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Info;
