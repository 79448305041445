import styled from 'styled-components';
import { Container as FloatContainer } from 'react-floating-action-button';

export const StyledFloat = styled(FloatContainer)`
  z-index: 2;
`;

export const Container = styled.div`
  background-color: #fcfaec;
`;

export const Section1 = styled.div`
  background: #fcfaec;
  .main-img {
    width: 80%;
    height: auto;
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
  .benefits-img {
    width: 100%;
    height: auto;
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  .benefits {
    width: 80% !important;
    margin: 0 auto !important;
  }
`;

export const Section2 = styled.div`
  margin-top: -105px;
  padding-top: 106px;

  @media (max-width: 1023px) {
    margin-top: -55px;
    padding-top: 40px;
  }

  background-color: #472e6d;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;

  .margin-icon {
    margin-bottom: 40px;
  }

  .margin-text {
    margin-bottom: 50px;
  }
`;

export const Section3 = styled.div`
  margin-top: -100px;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    .section {
      padding-top: 0px;
    }
  }

  .background {
    background: #fcfaec;
  }

  .margin-title {
    margin-top: 50px;
  }

  .margin-link {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const Section4 = styled.div`
  background-color: #472e6d;
  .section {
    @media (min-width: 769px) and (max-width: 1023px) {
      margin-bottom: -50px !important;
      padding-bottom: 0px !important;
    }
    @media (min-width: 1023px) {
      margin-bottom: -60px !important;
      padding-bottom: 0px !important;
    }
  }
`;

export const Section5 = styled.div`
  .section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const Section6 = styled.div`
  background: #fcfaec;
  margin-top: -105px;
  padding-top: 50px;
  padding-bottom: 50px;

  .margin-title {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  @media (max-width: 1023px) {
    margin-top: -55px;

    .margin-title {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .section {
      padding-top: 0px;
    }
  }
  .margin-icon {
    margin-top: 50px;
    margin-bottom: 80px;
  }
`;

export const AquiTem = styled.div`
  padding-top: 50px;
  max-width: 40%;
  min-width: 300px;
  margin: 0 auto;
`;

export const Section8 = styled.div`
  background: #fcfaec;
  margin-top: -106px;
  padding-top: 100px;

  .margin-title {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  @media (max-width: 1023px) {
    margin-top: -56px;
    .section {
      padding-top: 0px;
    }
    .margin-title {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .margin-icon {
    margin-top: 50px;
    margin-bottom: 80px;
  }
`;

export const VideoSection = styled.div`
  .section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const Section7 = styled.div`
  background: #fcfaec;

  .zigzag {
    background-repeat: repeat-x;
    height: 48px;
    width: 100%;
    background-image: url(${props => props.zigzag});
  }

  .container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
