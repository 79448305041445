/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledTextArea } from './styles';

function TextArea({
  id,
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  onFocus,
  disabled,
  pattern,
}) {
  return (
    <StyledTextArea>
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <textarea
        className="textarea"
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        pattern={pattern}
      />
    </StyledTextArea>
  );
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
};

TextArea.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  disabled: false,
  pattern: '',
};

export default TextArea;
