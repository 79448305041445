import styled from 'styled-components';

export const StyledInfo = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;

  h1 {
    font-family: 'LLCharlotte';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #563e8e;
  }

  .center-image {
    text-align: center !important;
  }

  .margin-image {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  img {
    height: 150px;
    width: auto;
  }

  .limit-width-text {
    margin: 0 auto;
    max-width: 240px;
  }
`;
