import styled from 'styled-components';

export const StyledPageTitle = styled.h1`
  font-family: Montserrat;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  font-size: ${props => props.fontsize}px;
  color: ${props => props.color};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  text-align: ${props => (props.center ? 'center' : 'none')};

  @media (max-width: 1023px) {
    font-size: 30px;
  }
  /* @media (max-width: 500px) {
    font-size: 30px;
  } */
`;
