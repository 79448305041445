/* eslint-disable react/no-unused-state */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Sticky from './components/Footer/Sticky';

import Home from './pages/Home';
// import Contact from './pages/Contact';
import Context from './components/LanguageContext/context';
import * as languages from './components/LanguageContext/index';

class Routes extends React.Component {
  constructor(props) {
    super(props);

    this.togglePt = () => {
      this.setState(() => ({
        languages: languages.pt,
      }));
    };
    this.toggleEn = () => {
      this.setState(() => ({
        languages: languages.en,
      }));
    };
    this.toggleEs = () => {
      this.setState(() => ({
        languages: languages.es,
      }));
    };
    this.toggleFr = () => {
      this.setState(() => ({
        languages: languages.fr,
      }));
    };

    this.state = {
      languages: languages.pt,
      togglePt: this.togglePt,
      toggleEn: this.toggleEn,
      toggleEs: this.toggleEs,
      toggleFr: this.toggleFr,
    };
  }

  render() {
    return (
      <BrowserRouter>
        <Context.Provider value={this.state}>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
          <Footer />
          <Sticky />
        </Context.Provider>
      </BrowserRouter>
    );
  }
}

export default Routes;
