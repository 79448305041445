export default {
  language: 'en',
  amazing: 'Amazing',
  nossaHistoria: 'Our Story',
  produtos: 'Products',
  beneficios: 'Benefits',
  sustentabilidade: 'Sustentability',
  contato: 'Contact',
  texto1:
    'Amazing Açaí comes from the idea between two brother entrepreneurs, who have been successful in Brazil working with acai berry for more than 20 years, with the brands Tia Augusta and Açaí Aju. In order to expand the boundaries of their business, they choose Portugal as their second home late 2018.',
  texto2:
    'Concerned with promoting a very high quality açaí blend, with unique flavor and nutritional value, Amazing Açaí installs its first pilot unit on European soil, located in the Industrial Park of Vendas Novas, Évora district. Driven by the Portuguese support framework PT2020, Amazing Açaí will invest in the expansion of its industrial unit in Portugal.',
  texto3:
    'Our açaí does not have, in its formula, gluten and lactose being a 100% vegan product. We strictly track our fruit suppliers in order to control the quality and hygiene of the raw material.',
  texto4:
    'Our pulp is considered Premium (or Special) where the concentration of soluble solids varies between 14% and 18% per 100 grams of product. Compared to other pulps - Popular (8% and 10%) and Medium (11% and 13%) it is undoubtedly the most complete and rich in nutrients, with strong and unique flavor, suggested for more complex recipes and for those looking for a product of superior quality.',
  texto5:
    'Acai is a berry fruit, native to the Amazon, where it has been consumed for thousands of years. It is considered a superfood because it is an important source of proteins, fibers, calcium, potassium, vitamins A, C, E and from the complex B. It is rich in polyphenols, especially from the class of anthocyanins, responsible for its high antioxidant power, stimulating the brain functions, fighting free radicals and anti-aging.',
  texto6:
    'This fruit is also known for its high rates of essential fatty acids (omegas 6 and 9), which aids in lowering LDL cholesterol, and increasing HDL cholesterol, contributing to the prevention of cardiovascular diseases and arteriosclerosis. The acai berry is synonymous with energy and vitality.',
  texto7:
    'The raw material used on our blend, comes from the Amazon Forest, more precisely from the state of Pará, Brazil. Amazing strengthens the local economy by prioritizing small producers.',
  contatoText: {
    titulo: 'Contact',
    subtitulo: 'Become an Amazing Distributor',
    nome: 'Name *',
    email: 'Email *',
    assunto: 'Subject *',
    mensagem: 'Message *',
    botao: 'Send',
    validador: 'All fields are mandatory!',
    sucesso: 'Message sent successfully!',
    erro: 'An error occurred while sending the message. Try again!',
    sede: 'Company Headquarters',
    industria: 'Industry',
  },
  distribuidores: {
    titulo: 'Our Distributors',
  },
  tabela: 'View Nutrition Facts',
  tabelaHide: 'Hide Nutrition Facts',
  tabelaDados: {
    campo1: 'Energy Value',
    campo2: 'Total Fat',
    campo3: 'Satured Fat',
    campo4: 'Carbohydrate',
    campo5: 'Total Sugars',
    campo6: 'Dietary Fiber',
    campo7: 'Protein',
    campo8: 'Salt',
    campo9: 'Vitamin C',
    campo10: 'Potassium',
    campo11: 'Iron',
    campo12: 'Calcium',
    campo13: 'Gluten',
  },
  nomesProdutos: {
    original: 'Acai Original',
    premium: 'Acai Original',
    banana: 'Acai Original',
    frutas: 'Acai With Red Fruits',
  },
  topo: 'Top!',
};
