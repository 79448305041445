import styled from 'styled-components';

export const BannerImage = styled.div`
  margin-top: 0px;
  padding-top: 0px;
  background: url(${props => props.bgUrl});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 70vh;
  z-index: -1;

  .padding-title {
    padding-top: 30vh;
  }

  @media (max-width: 600px) {
    height: 40vh;
    padding-top: 5vh;

    .padding-title {
      padding-top: 10vh;
    }
  }
`;

export const BeigeSection = styled.div`
  margin-top: -100px;
  z-index: 0;
`;

export const WhiteSection = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  z-index: 0;
  margin-top: -100px;
  svg {
    fill: #ffffff !important;
  }
`;
