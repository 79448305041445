export default {
  language: 'fr',
  amazing: 'Amazing',
  nossaHistoria: 'Notre Histoire',
  produtos: 'Produits',
  beneficios: 'Bénéfices',
  sustentabilidade: 'Durabilité',
  contato: 'Contact',
  texto1:
    'Amazing Açai est née de l’idée de deux frères entrepreneurs, ils travaillent tous les deux ensemble  depuis plus de 20 ans avec leurs propre marques ( Tia Augusta et Açai Aju ) qui sont renommer pour leurs qualité, de ce fait ces entreprises sont devenu fleurissantes et prospères au Brésil. Dans le but d’étendre leurs commerce au-delà des frontières Brésiliennes, ils ont choisies comme deuxième maison le Portugal depuis fin 2018.',
  texto2:
    "Soucieux de promouvoir un assemblage d'açaí de très haute qualité, à la saveur et à la valeur nutritive uniques, Amazing Açaí installe sa première unité pilote sur le sol européen, situé dans le parc industriel de Vendas Novas, district d'Évora. Porté par le cadre de soutien portugais PT2020, Amazing Açaí investira dans l'expansion de son unité industrielle au Portugal.",
  texto3:
    "Notre açaí n'a pas, dans sa formule, le gluten et le lactose étant un produit 100% vegan. Nous suivons strictement nos fournisseurs de fruits afin de contrôler la qualité et l'hygiène de la matière première.",
  texto4:
    'Notre pulpe est considérée comme Premium (Special) car la concentration de solides solubles varie entre 14% et 18% pour une portion de 100 grammes de produit. Comparer aux autres pulpes, la plus populaire contient de 8% à 10% de Solides soluble, quand à l’autre gamme appelé « Moyenne » en contient 11% à 13%. Nous possédons donc la plus complète et nutritive, ainsi que la plus forte et unique en terme de saveur, cette pulpe est recommandé pour les recettes plus complexes et pour ceux qui cherche à obtenir un produit finit de haute qualité.',
  texto5:
    'L’Açai est une Baie qui pousse en Amazonie, ou cette dernière est consommé depuis des milliers d’années. Elle est considéré comme faisant partie de la famille des SuperAliment car elle est une source importante de protéines , fibres, calcium, potassium, vitamines A, C, E et Vitamine B complexe. Elle est aussi riche en Polyphénoles mais surtout en anthocyane, lesquelles sont responsable de ça contenance importante en Antioxydant stimulant les fonctions cérébrales et combattant les radicaux libres, de plus il a été prouvé que tous ces bienfaits lui donne un effet anti-âge.',
  texto6:
    'Ce fruit est aussi renommé pour son haut taux en acides gras essentiel (Omega 6 et 9), lesquelles participe à la réduction du cholestérol LDL ainsi qu’a l’augmentation du cholestérol HDL, contribuant à la prévention des maladies cardiovasculaires et de l’artériosclérose. La baie d’Açai est synonyme d’énergie et de vitalité.',
  texto7:
    'Les matières premières utilisé dans notre mélange proviennent de la Forêt Amazonienne, plus précisément de l’état de Para, Brésil. Amazing participe au renforcement de l’économie locale en priorisant les petits producteurs.',
  contatoText: {
    titulo: 'Contact',
    subtitulo: 'Devenir Distributeur Amazing Açai!',
    nome: 'Nom *',
    email: 'Email *',
    assunto: 'Sujet *',
    mensagem: 'Message *',
    botao: 'Envoyer',
    validador: 'Tous les champs sont obligatoires!',
    sucesso: 'Message envoyé avec Succès!',
    erro: "Une erreur s'est produite lors de l'envoi du message. Réessayer!",
    sede: 'Siege Commerciale',
    industria: 'Industrie',
  },
  distribuidores: {
    titulo: 'Nos distributeurs',
  },
  tabela: 'Voir les bienfaits nutritionnelles',
  tabelaHide: 'Cacher les bienfaits nutritionnelles',
  tabelaDados: {
    campo1: 'Valeur Énergétique',
    campo2: 'Matières Grasses',
    campo3: 'Dont Saturées',
    campo4: 'Glucides',
    campo5: 'Dont Socres',
    campo6: 'Fibre Alimentaire',
    campo7: 'Proteínes',
    campo8: 'Sel',
    campo9: 'Vitamine C',
    campo10: 'Potassium',
    campo11: 'Fer',
    campo12: 'Calcium',
    campo13: 'Gluten',
  },
  nomesProdutos: {
    original: 'Açai original',
    premium: 'Açai original',
    banana: 'Açai original',
    frutas: 'Açai avec fruits rouges',
  },
  topo: 'Top!',
};
